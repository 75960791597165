<template>
  <div>
    <v-card
      :class="!renderInCourseDesign ? 'Assignment-card' : 'rounded-xl border-1-2 border-solid border-primary pa-1'"
      class="mx-auto"
      max-width="550"

    >
      <div class="pa-0 ma-0">
        <v-row class="pa-0 Assignment-title-grade-dropdown-section">
          <v-col cols="7" class="pa-0 ma-0">
            <v-card-title
              class="my-0 py-0 pl-0 mk-assignment-title custom-font-family text-truncate"
            >
              {{ assignment.title }}
            </v-card-title>
            <v-card-subtitle
              class="pt-1 mt-1 ml-0 pl-0 mk-assignment-upload-date custom-font-family"
            >
              {{ !$language ? "Upload Date" : "প্রকাশের তারিখ" }} :
              {{
                !$language
                  ? assignment.upload_date
                  : new Date(assignment.upload_date).toLocaleString("bn-BD", {
                      dateStyle: "medium",
                    })
              }}
            </v-card-subtitle>
          </v-col>
          <v-col
            v-if="!renderInCourseDesign"
            cols="4"
            class="pa-0 ma-0 d-flex justify-end"
          >
            <v-btn
              depressed
              plain
              :ripple="{ center: true }"
              class="mk-assignment-btn-grade custom-font-family"
              :to="`assignments/${assignment.object_id}`"
              style="margin-right: 1rem"
            >
              <img src="../assets/Grade.svg" width="25" height="26" alt="" />
              {{ !$language ? "Grade" : "গ্রেড" }}
            </v-btn>
          </v-col>
          <v-col
            v-if="!renderInCourseDesign"
            cols="1"
            class="pa-0 ma-0 Assignment-dropdown-section"
          >
            <div class="text-center d">
              <v-menu offset-y left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="isOwner"
                    color="black"
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <!-- Backend does not support this feature -->

                  <!-- <v-list-item class="Assignment-card-menu-list-item clickable">
                    <v-list-item-title class="subtitle-2 custom-font-family">
                      <v-icon>fas fa-list</v-icon> Edit Assignment</v-list-item-title
                    >
                  </v-list-item> -->

                  <v-list-item
                    class="Assignment-card-menu-list-item clickable"
                    @click="$emit('delete', assignment)"
                  >
                    <v-list-item-title class="subtitle-2 custom-font-family">
                      <v-icon>fas fa-list</v-icon
                      >{{
                        !$language
                          ? "Delete Assignment"
                          : "অ্যাসাইনমেন্ট ডিলিট করুন"
                      }}</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-col>
        </v-row>

        <v-row
          v-if="!renderInCourseDesign"
          class="px-0 mb-0 py-0 mk-assignment-batches-total-mark-section"
        >
          <v-col cols="6" class="pa-0 ma-0 mk-assignment-share-with-batches">
            <v-card-subtitle
              v-if="!$language"
              class="custom-font-family pa-0 ma-0"
            >
              <img
                src="../assets/Share_N_Batch.svg"
                alt=""
                height="31"
                width="31"
                class="mr-2"
              />
              With {{ share_batches_counts }} Batches
            </v-card-subtitle>
            <v-card-subtitle v-else class="custom-font-family pa-0 ma-0">
              <img
                src="../assets/Share_N_Batch.svg"
                alt=""
                height="31"
                width="31"
                class="mr-2"
              />
              {{ share_batches_counts }} টি ব্যাচের সাথে শেয়ার করা হয়েছে
            </v-card-subtitle>
          </v-col>
          <v-col cols="5" class="pa-0 ma-0 mk-assignment-total-mark">
            <!-- Current condition does not support this feature -->
            <!-- <v-card-subtitle class="custom-font-family pa-0 ma-0">
              <img
                src="../assets/Total_Mark.svg"
                alt=""
                height="31"
                width="31"
                class="mr-2"
              />
              Total Mark: {{ assignment.total_score }}
            </v-card-subtitle> -->
          </v-col>
        </v-row>
      </div>

      <v-row class="pa-0 mk-share-detail-section">
        <v-col cols="5" class="d-flex justify-left ma-0 pa-0 mk-detail">
          <v-btn
            depressed
            tile
            plain
            class="custom-font-family"
            @click="preview()"
          >
            {{ !$language ? "Details" : "বিস্তারিত" }}
          </v-btn>
        </v-col>
        <v-col
          v-if="!renderInCourseDesign"
          cols="7"
          class="d-flex ma-0 pa-0 mk-share"
        >
          <v-btn
            depressed
            tile
            plain
            class="custom-font-family"
            @click="$emit('share')"
          >
            <img
              src="../assets/Share_Btn.svg"
              width="25"
              height="25"
              alt=""
              class="mr-2"
            />
            {{ !$language ? "Share" : "শেয়ার করুন" }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <v-dialog v-model="isFilePreviewerVisible" width="100vw">
      <file-previewer
        v-if="isFilePreviewerVisible"
        :preview-url="previewUrl"
        :download-url="downloadUrl"
        :title="assignment.title"
        :is-video="assignment.is_video ? assignment.is_video : false"
        @cancel="isFilePreviewerVisible = false"
      ></file-previewer>
    </v-dialog>
  </div>
</template>

<script>
import FilePreviewer from "~ecf/components/FilePreviewer";
import courseWorkService from "../services/CourseworkService";
export default {
  name: "MAssignmentListItem",
  components: { FilePreviewer },
  props: {
    assignment: {
      type: Object,
      required: true,
    },
    renderInCourseDesign: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      isFilePreviewerVisible: false,
      previewUrl: "",
      downloadUrl: "",
      retrieving: false,
    };
  },
  computed: {
    share_batches_counts() {
      if (this.renderInCourseDesign) return 0;
      else return this.assignment.shared_batches.length;
    },
    currentOrganization() {
      return this.$store.state.organization.currentOrganization;
    },
    isOwner() {
      return this.$store.state.user.user.access[
        this.currentOrganization.partner_code
      ].includes("owner");
    },
  },

  methods: {
    async preview() {
      if (this.retrieving) return;
      if (this.assignment.is_available === false) return;
      try {
        this.retrieving = true;
        this.url = "loading";
        let a = await courseWorkService.get(
          this.assignment.object_id,
          this.assignment.course_id
        );
        this.previewUrl = a.preview_url;
        this.downloadUrl = a.download_url;
        this.isFilePreviewerVisible = true;
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.retrieving = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "#ecf/assignment/styles/MAssignmentListItem.scss";
</style>
